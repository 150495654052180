import { SequenceLabel } from 'common/ontology'
import React from 'react'
import { BaseLabels } from '../../components/Labels'

const SEQUENCE_LABEL_TEXT: Record<SequenceLabel, string> = {
  a_chute: 'A chuté',
  absent: 'Absent',
  appel_a_l_aide: "Appel à l'aide",
  appel_de_confort: 'Appel de confort',
  as_au_loin: 'AS au loin',
  aux_toilettes: 'Aux toilettes',
  besoin_assistance_urgent: "Besoin d'assistance urgent",
  besoin_attention_particuliere: "Besoin d'une attention particulière",
  bruit_exterieur: 'Bruit extérieur',
  change: 'Change',
  comportement_pathologique: 'Comportement pathologique',
  crise_agitation: "Crise d'agitation",
  dans_la_piece: 'Dans la pièce',
  dans_une_piece_voisine: 'Dans une pièce voisine',
  deambulation: 'Déambulation',
  detresse_respiratoire: 'Détresse respiratoire',
  essaie_aller_ailleurs: "Essaie d'aller ailleurs",
  est_au_sol: 'Est au sol',
  fait_sa_toilette: 'Fait sa toilette',
  gemissements_repetes: 'Gémissements répétés',
  hurlements_repetes: 'Hurlements répétés',
  intrusion_autre_resident: 'Intrusion autre résident',
  joyeux: 'Joyeux',
  lit: 'Lit',
  machine: 'Machine',
  mange: 'Mange',
  menage: 'Ménage',
  monologue: 'Monologue',
  parle_a_qqun: 'Parle à qqun',
  parle_au_telephone: 'Parle au téléphone',
  parle_seul: 'Parle seul',
  passage_recent: 'Passage récent',
  piece_voisine: 'Pièce voisine',
  presence_as: 'Présence AS',
  presence_autre_resident: 'Présence autre résident',
  presence_famille_proche: 'Présence famille / proche',
  probleme_ecoute_des_sons: "Problème à l'écoute des sons",
  rangement: 'Rangement',
  salle_de_bain: 'Salle de bain',
  se_deplace_debout: 'Se déplace / Debout',
  silence: 'Silence',
  sommeil_agite: 'Sommeil agité',
  sommeil_normal: 'Sommeil normal',
  sorti_accompagne: 'Sorti accompagné',
  sorti_seul: 'Sorti seul',
  souffrance_respiratoire: 'Souffrance respiratoire',
  stress: 'Stress',
  tambourinages_repetes: 'Tambourinages répétés',
  television: 'Télévision',
  toilette_au_lit: 'Toilette au lit',
  toilette_sdb: 'Toilette SdB',
  vient_de_rentrer_accompagne: 'Vient de rentrer accompagné',
  vient_de_rentrer_seul: 'Vient de rentrer seul',
  vomissement: 'Vomissement',
}

function descriptionWithHighlight(description: string, highlight: string) {
  const parts = description.split(new RegExp(`(${highlight})`))
  return (
    <>
      {...parts.map((substr, index) =>
        substr === highlight ? (
          <b key={index}>{highlight}</b>
        ) : (
          <React.Fragment key={index}>{substr}</React.Fragment>
        ),
      )}
    </>
  )
}

const SEQUENCE_LABEL_DESCRIPTION: Record<SequenceLabel, string | JSX.Element> =
  {
    a_chute: descriptionWithHighlight(
      "Le résident a chuté il y a moins de 30 minutes. Indiquer ce label qu'il se soit relevé ou non. A l’arrivée d’une personne qui peut porter assistance, désactiver ce label.",
      '30 minutes',
    ),
    absent:
      "Le résident est hors de la zone d'écoute du ARI donc à priori ni dans la pièce du ARI, ni dans une pièce voisine.",
    appel_a_l_aide: descriptionWithHighlight(
      'Le résident verbalise intentionnellement un besoin d’aide qui nécessite une intervention urgente, il appelle quelqu’un ”au secours”, “SOS”, “à l’aide”… Les hurlements de douleur ou les tambourinages ne sont pas suffisants, il faut des mots intelligibles autres que des interjections ("Ah", "oh"...).',
      'il appelle',
    ),
    appel_de_confort: descriptionWithHighlight(
      'Le résident verbalise intentionnellement un besoin d’aide mais qui ne nécessite pas une intervention urgente, il appelle car il a froid, il a besoin d’aller au toilette, il a besoin d’être rassuré... Les hurlements de douleur ou les tambourinages ne sont pas suffisants, il faut des mots intelligibles autres que des interjections ("Ah", "oh"...).',
      'il appelle',
    ),
    as_au_loin:
      'AS dans le couloir, mais trop loin pour entendre ce qui se passe dans la chambre.',
    aux_toilettes: 'Elimination dans la salle de bain ou sur un montauban.',
    besoin_assistance_urgent:
      'La situation nécessite nécessite une intervention immédiate, chute, souffrance, perte de connaissance, étouffement...',
    besoin_attention_particuliere: "Pas d'urgence, appel de confort.",
    bruit_exterieur:
      'Le son provient de l’extérieur du bâtiment, souvent perceptible lorsqu’une fenêtre est ouverte. Il peut s’agir du vent, de la pluie, des voitures qui passent, bruit de la nature, travaux, son de rue....',
    change: 'Renouvellement de protection, pull ups.',
    comportement_pathologique:
      'Cris ou appels irrationnels, action répétitive, on ressent une attitude pathologique.',
    crise_agitation:
      "Le résident est agité de manière générale. L'agitation peut se manifester physiquement ou verbalement. Il ne s'agit pas d'une saute d'humeur ou d'un tambourinage court.",
    dans_la_piece: 'Le résident est dans la pièce écoutée.',
    dans_une_piece_voisine:
      'Le résident est dans une pièce voisine de la pièce écoutée, par exemple le couloir en établissement, ou une autre pièce à domicile.',
    deambulation:
      'Le résident se déplace de manière pathologique, il sort, il entre, il sort… ou tourne en rond dans la pièce.',
    detresse_respiratoire: descriptionWithHighlight(
      'Le résident a un problème respiratoire grave qui nécessite une intervention immédiate.',
      'nécessite une intervention immédiate',
    ),
    essaie_aller_ailleurs: 'Tentative de sortie de chambre.',
    est_au_sol:
      'Le résident est au sol suite à une chute ou une glissade, il arrive parfois qu’un résident s’assoit “silencieusement“ et reste au sol. Si le résident se relève, désactiver ce label.',
    fait_sa_toilette:
      'Le résident réalise un soin d’hygiène dans la salle de bain.',
    gemissements_repetes:
      'Gémit à plusieurs reprises sur une longue période, par intermittence ou non.',
    hurlements_repetes:
      'Crie à fort volume à plusieurs reprises sur une longue période, par intermittence ou non.',
    intrusion_autre_resident:
      'Situation d’un résident qui rentre dans une chambre qui n’est pas la sienne. Le résident est mal reçu, sa présence n’est pas souhaitée.',
    joyeux:
      'La résidente rigole, chante, sifflote, parle avec allégresse, parle de chose joyeuse et semble heureuse.',
    lit: 'Le résident se trouve sur son lit.',
    machine:
      'Présence d’un bruit de machine continu. Son bruyant qui perturbe la compréhension du sommeil.',
    mange:
      'Le résident prend son repas ou une collation (compote, verre d’eau).',
    menage: 'Nettoyage par le personnel.',
    monologue:
      'Désigne un flot de parole incessant ou un flot de parole courte sur une longue période, il y a un côté pathologique.',
    parle_a_qqun:
      'Le résident s’adresse à un AS, à de la famille, à un autre résident...',
    parle_au_telephone:
      'On comprend que le résident a une conversation téléphonique (ou en visio).',
    parle_seul:
      "Le résident pense à voix haute, mais n'est pas en monologue. Les cris, gémissements même isolés ne correspondent pas à ce label.",
    passage_recent: descriptionWithHighlight(
      "AS présente moins de 5 minutes avant l'instant.",
      '5 minutes',
    ),
    piece_voisine:
      "Ambiance bruyante provenant d’une autre pièce au sein de l'établissement (couloir, pièce voisine, cuisine).",
    presence_as: "Dans la chambre ou devant l'entrée.",
    presence_autre_resident:
      'Au moins deux résidents présents, aucun stress, par exemple visite d’un autre résident, chambre double, conjoint à domicile.',
    presence_famille_proche: 'Enfant, petits enfants, ami, fils, père, oncle…',
    probleme_ecoute_des_sons: 'Ecoute impossible, son altéré ou aucun son.',
    rangement: 'Le résident manipule ses affaires, fouille dans un tiroir.',
    salle_de_bain:
      'Le résident est dans la salle de bain (toilette, lavabo, douche, ...).',
    se_deplace_debout:
      'Dans la pièce - marche, station debout, déplacement en fauteuil électrique ou manuel.',
    silence:
      'Ambiance silencieuse assez longue avec ou sans présence résident. Faible production de volume sonore.',
    sommeil_agite:
      "Bruit de drap / vocalisation nocturne / sommeil interrompu /... Un résident qui bouge, gigote, se retourne souvent dans son lit (bruit de lit drap), qui pousse des petits gémissements, s'agace oralement. Ressentir de l'inconfort.",
    sommeil_normal:
      'Ronflement / discret sans bcp de production sonore mais endormi / respiration lente / ...',
    sorti_accompagne: descriptionWithHighlight(
      "Le résident a quitté la pièce accompagné d'un AS ou d'un membre de sa famille il y a moins de 5 minutes.",
      '5 minutes',
    ),
    sorti_seul: descriptionWithHighlight(
      'Le résident est sorti de la pièce sans accompagnateur il y a moins de 5 minutes.',
      '5 minutes',
    ),
    souffrance_respiratoire:
      'Le résident  semble ressentir une souffrance respiratoire.',
    stress:
      'Le stress se ressent par la parole mais également lorsqu’un résident tambourine, à des difficultés respiratoires, vomit, gémit ...',
    tambourinages_repetes:
      'Tambourine à plusieurs reprises sur une longue période, par intermittence ou non.',
    television: 'Télévision ou radio allumée.',
    toilette_au_lit: "Soin d'hygiène réalisé au lit.",
    toilette_sdb: "Soin d'hygiène réalisé à la SdB, douche.",
    vient_de_rentrer_accompagne: descriptionWithHighlight(
      "Le résident a regagné la pièce accompagné d'un AS ou d'un membre de sa famille il y a moins de 5 minutes.",
      '5 minutes',
    ),
    vient_de_rentrer_seul: descriptionWithHighlight(
      'Le résident a regagné la pièce sans accompagnateur il y a moins de 5 minutes.',
      '5 minutes',
    ),
    vomissement: descriptionWithHighlight(
      'Le résident est dans un état nauséeux (il faut aller chercher la bassine), est en train de vomir ou a vomi il y a moins de 5 minutes.',
      '5 minutes',
    ),
  }

const SEQUENCE_LABELS: { title: string; labels: SequenceLabel[] }[] = [
  { title: 'Déplacement', labels: ['se_deplace_debout', 'deambulation'] },
  {
    title: 'Localisation résident',
    labels: [
      'dans_la_piece',
      'dans_une_piece_voisine',
      'absent',
      'lit',
      'salle_de_bain',
    ],
  },
  {
    title: 'Entrée / sortie du résident',
    labels: [
      'sorti_seul',
      'sorti_accompagne',
      'vient_de_rentrer_seul',
      'vient_de_rentrer_accompagne',
      'essaie_aller_ailleurs',
    ],
  },
  { title: 'Sommeil', labels: ['sommeil_normal', 'sommeil_agite'] },
  {
    title: 'Parole',
    labels: ['parle_seul', 'monologue', 'parle_a_qqun', 'parle_au_telephone'],
  },
  {
    title: 'Activité résident',
    labels: ['mange', 'fait_sa_toilette', 'aux_toilettes'],
  },
  {
    title: 'Présence',
    labels: [
      'intrusion_autre_resident',
      'presence_autre_resident',
      'presence_famille_proche',
    ],
  },
  {
    title: 'Localisation AS',
    labels: ['presence_as', 'as_au_loin', 'passage_recent'],
  },
  {
    title: 'Activité AS',
    labels: ['change', 'toilette_au_lit', 'toilette_sdb'],
  },
  {
    title: 'Ambiance',
    labels: [
      'television',
      'rangement',
      'menage',
      'machine',
      'silence',
      'bruit_exterieur',
      'piece_voisine',
    ],
  },
  { title: 'Humeur', labels: ['joyeux', 'stress'] },
  {
    title: 'Appel',
    labels: ['appel_a_l_aide', 'appel_de_confort'],
  },
  { title: 'Chute', labels: ['est_au_sol', 'a_chute'] },
  {
    title: 'Autres phénomènes inquiétants',
    labels: [
      'crise_agitation',
      'tambourinages_repetes',
      'hurlements_repetes',
      'gemissements_repetes',
      'vomissement',
    ],
  },
  {
    title: 'Respiration',
    labels: ['souffrance_respiratoire', 'detresse_respiratoire'],
  },
  {
    title: "Besoin d'assistance",
    labels: [
      'besoin_assistance_urgent',
      'besoin_attention_particuliere',
      'comportement_pathologique',
    ],
  },
  {
    title: 'Manque de données',
    labels: ['probleme_ecoute_des_sons'],
  },
]

type SequenceLabelsProps = {
  selectedLabels: SequenceLabel[]
  prevSelectedLabels: SequenceLabel[]
  tag: (label: SequenceLabel) => void
  showDescriptions: boolean
}

export const SequenceLabels = ({
  selectedLabels,
  prevSelectedLabels,
  tag,
  showDescriptions,
}: SequenceLabelsProps) => (
  <div className="grow">
    <BaseLabels
      labels={SEQUENCE_LABELS}
      labelText={SEQUENCE_LABEL_TEXT}
      labelDescription={SEQUENCE_LABEL_DESCRIPTION}
      selectedLabels={selectedLabels}
      prevSelectedLabels={prevSelectedLabels}
      onLabelClick={tag}
      showDescriptions={showDescriptions}
    />
  </div>
)
